<template>
    <div class="events-filter d-flex flex-column flex-md-row flex-xl-column">
        <div class="form-group">
            <v-select
                v-model="partner"
                :options="partnersOptions"
                label="name"
                @input="filter()"
            ></v-select>
        </div>
        <div class="form-group">
            <v-select
                v-model="specialty"
                :options="specialtiesOptions"
                label="name"
                @input="filter()"
            ></v-select>
        </div>
        <div class="form-group mb-0">
            <v-date-picker
                class="date-picker"
                v-model="date"
                mode="date"
                :masks="masks"
            >
                <template v-slot="{ inputValue, togglePopover }">
                    <div class="d-flex align-items-center">
                        <button class="btn" @click="togglePopover()">
                            <svg
                                width="17"
                                height="17"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fill="#959595"
                                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                ></path>
                            </svg>
                        </button>
                        <input
                            @focus="togglePopover()"
                            @blur="togglePopover()"
                            :value="inputValue"
                            readonly
                            :placeholder="`-- ${$t('date')} --`"
                        />

                        <button 
                        @click="date = null"
                        class="btn reset-date-picker">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#959595"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
                        </button>
                    </div>
                </template>
            </v-date-picker>
        </div>
    </div>
</template>

<script>
import { getSpecialty, getPartnersForFilter } from "@/API/utilites";

export default {
    name: "EventFilter",
    data() {
        return {
            masks: {
                input: "YYYY-MM-DD",
            },
            date: null,
            specialtiesOptions: [],
            specialty: {
                id: null,
                name: `-- ${this.$t('specialty')} --`
            },
            partnersOptions: [],
            partner: {
                id: null,
                name: `-- ${this.$t('partners')} --`
            },
        };
    },
    watch: {
        date(val) {
            if (val) this.date = this.$moment(new Date(val)).format("YYYY-MM-DD");
            this.filter();
        },
    },
    methods: {
        async initFilters() {

            await getPartnersForFilter().then(res => {
                if (res.status == 200) {
                    // console.log(res.data);
                    const partners = [
                        {
                            id: null,
                            name: `-- ${this.$t('partners')} --`
                        },
                        ...res.data?.data?.partner
                    ];
                                
                    this.partnersOptions = partners;
                }
            });

            await getSpecialty().then(res => {
                if (res.status == 200) {
                    const specialties = [
                        {
                            id: null,
                            name: `-- ${this.$t('specialty')} --`
                        },
                        ...res.data?.data
                    ];

                    this.specialtiesOptions = specialties;
                }
            });

            
        },
        filter() {

            const filters = {
                partner_id: this.partner.id,
                speciality_id: this.specialty.id,
                time: this.date
            };
            
            this.$emit('filter', filters);
        }
    },
    mounted() {
        this.initFilters();
    }
};
</script>
